import { useMutation, useQueryClient, UseMutationOptions } from '@tanstack/react-query'
import { navigate } from 'gatsby'
import axios from 'axios'
import useStorage from './useStorage' // Adjust the import path as necessary
import siteConfig from '@theme/utils/siteConfig'
import { setSentryCustomer, clearSentryCustomer } from '@theme/utils/sentryHelpers'
import trackAmplitudeEvent from '@theme/utils/trackAmplitudeEvent'

// re-use types from react-query for mutation options
type UseLoginOptions = Omit<
  UseMutationOptions<
    any,
    unknown,
    {
      [x: string]: any
    },
    unknown
  >,
  'mutationFn'
>

const getTokenExpiryDate = () => {
  const now = new Date()
  const ninetyDays = 90 * 24 * 60 * 60 * 1000 // 90 days in milliseconds
  const futureDate = new Date(now.getTime() + ninetyDays)
  const isoString = futureDate.toISOString()
  return isoString
}

export default function useLogin({ onSuccess, ...rest }: UseLoginOptions = {}) {
  const queryClient = useQueryClient()
  const { setItem, removeItem } = useStorage()

  const loginMutation = useMutation(
    async ({ email, password }: { email: string; password: string; [x: string]: any }) => {
      try {
        const response = await axios.post(`https://api.commerce7.com/v1/customer/login?bypassSentry=true`, {
          email,
          password,
        })
        console.log('loginMutation response data:', response.data)
        return response.data
      } catch (error) {
        trackAmplitudeEvent({
          eventName: 'Login Failed',
          eventProps: {
            email,
            errorMessage: error.message,
            statusCode: error.response?.status,
          },
        })

        if (axios.isAxiosError(error)) {
          console.error('Axios error response data:', error.response?.data)
          throw new Error(error.message)
        }
        throw error // Rethrowing non-Axios errors
      }
    },
    {
      onSuccess: async (data, ...args) => {
        if (!data?.token) {
          throw new Error('No token')
        }
        const decodedTokenPayload = atob(data.token.split?.('.')?.[1])
        const tokenData = decodedTokenPayload && JSON.parse(decodedTokenPayload)

        trackAmplitudeEvent({
          eventName: 'Login Successful',
          eventProps: {
            email: tokenData.email,
            userId: tokenData.sub,
          },
        })

        console.log('tokenData.sub', tokenData.sub);

        setSentryCustomer({ id: tokenData.sub, email: tokenData.email })

        saveTokenToStorage({ token: data.token, queryClient })

        // Refetch cartId query to ensure it runs immediately after login

        console.log('Refetched cartId query after login')

        // optional callback
        onSuccess && onSuccess(data, ...args)
      },
      ...rest,
    }
  )

  const logout = (performRedirect = false) => {
    console.log('useLogin logout')

    // remove token and tokenExpiryDate from storage
    removeItem('token')
    removeItem('tokenExpiryDate')
    removeItem('cartId')

    // remove all queries from react-query
    queryClient.removeQueries()

    clearSentryCustomer()

    if (performRedirect) {
      navigate((siteConfig && siteConfig.loginPath) || '/login')
    }
  }

  const saveTokenToStorage = ({ token, queryClient }) => {
    console.log('saveTokenToStorage running')
    // storing token and timestamp in storage
    setItem('token', token)
    setItem('tokenExpiryDate', getTokenExpiryDate())

    // invalidate token query
    queryClient.invalidateQueries(['token'])
    return true
  }

  return { ...loginMutation, login: loginMutation.mutate, logout, saveTokenToStorage }
}
